@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
  .rtl {
    direction: rtl;
  }
}

::-webkit-scrollbar {
  display: none;
}

#navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 80px;
  transition: 0.5s;
  /*and a transition time for a smooth appearence*/
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 16px;
@import "node_modules/aos/src/sass/aos.scss";

.launch_pill {
  width: max-content;
  color: #aeaeae;
  padding: 0.3rem 1rem;
  font-size: 0.8rem;
  border-radius: 2rem;
  border: 1px solid #ffffff27;
  background: linear-gradient(30deg, #39bab61e, #667eea16);
  backdrop-filter: blur(8.4px);
  box-shadow: 0 4px 30px #00000011;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin: 0 0 2rem 0;
}

@media screen and (max-width: 767px) {
  .launch_pill {
    margin: 2rem auto;
    font-size: 0.65rem;
    padding: 0.2rem 0.7rem;
    text-wrap: wrap;
    overflow-wrap: break-word;
  }
}
